<template>
  <div>
    <div class="main">
      <!-- <v-container grid-list-xl>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg4 xl4>
            <div
              class="
                text-h5
                yellow
                lighten-4
                pa-5
                ma-2
                text-decoration-underline
                d-flex
                align-center
                justify-center
              "
            >
              <div class="ma-6">
                <img src="/static/images/powerball_mini.png" />
              </div>
              <div>
                <a
                  href="https://www.cnn.com/2022/11/08/us/powerball-lottery-record-delayed-drawing-tuesday-trnd/index.html"
                  >Winning numbers for the record $1.9 billion Powerball jackpot
                  have yet to be announced after drawing was delayed</a
                >
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container> -->

      <!-- <v-container grid-list-xl>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg8 xl8>
            <div class="ma-6">
              <img src="/static/images/suspended.jpg" alt="Suspended" />
            </div>
            <div v-html="$t('message.suspended')"></div>
          </v-flex>
        </v-layout>
      </v-container> -->

      <v-container grid-list-xl>
        <div class="light-green accent-2 ma-3 pa-3 text-h6">
          <div class="d-flex justify-center align-center">
            <div class="text-h4 font-weight-black ">IMPORTANT</div>
          </div>
          <div class="d-flex justify-center align-center">
            <div>{{$t('message.newEmail')}}</div>
          </div>
          <div class="d-flex justify-center align-center font-weight-black text-h4">
            <div>{{$t('message.interacEmail')}}</div>
          </div>
          <div class="d-flex justify-center align-center text-h5">
            <div v-html="$t('message.interacPassword')"></div>
          </div>
        </div>
      </v-container>

      <!-- <emb-email-verified
        class="pt-2"
        v-if="!hideEmailVerificationBanner"
      ></emb-email-verified> -->
      <emb-notifications class="pt-2"></emb-notifications>
      <div class="banner">
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg8 xl8>
              <emb-home-main-banner :data="homeBannerData">
              </emb-home-main-banner>
            </v-flex>
            <v-flex xs12 sm12 md12 lg4 xl4>
              <v-layout row wrap text-center mx-0>
                <v-flex xs12 sm6 md6 lg12 xl12 px-0>
                  <emb-aside-banner-lottery type="megamillions">
                  </emb-aside-banner-lottery>
                </v-flex>
                <v-flex xs12 sm6 md6 lg12 xl12 px-0>
                  <emb-aside-banner-lottery type="powerball">
                  </emb-aside-banner-lottery>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <emb-features class="mb-4"> </emb-features>
      <!--
      <emb-feature-product
        secTitle="Featured Products"
        :data="featureProductData"
      >
      </emb-feature-product>
      -->
      <!--
      <emb-day-deal :data="dayDealData"> </emb-day-deal> -->
      <!-- <emb-detail-offer></emb-detail-offer> -->
      <!-- <emb-feature-product secTitle="New Arrivals" :data="latestArrivalData">
      </emb-feature-product> -->
      <!-- <embTestimonial secTitle="Customer Reviews" :data="TestimonialData">
      </embTestimonial> -->
      <!-- <emb-blog secTitle="Latest From Blogs" :data="blogData"> </emb-blog> -->
      <!-- <emb-subscribe
        heading="Subscribe our Newsletter"
        description="Stay up to date with our latest new and products"
      >
      </emb-subscribe> -->
      <!-- <emb-shop-card secTitle="Shop By Brands" :data="shopCard">
      </emb-shop-card> -->
    </div>
  </div>
</template>
<script>
// layout components
import HomeMainBanner from "Components/Layouts/Banner/HomeMainBanner";
import AsideBannerLottery from "Components/Layouts/Banner/AsideBannerLottery";
// import AsideBannerV2 from "Components/Layouts/Banner/AsideBannerV2";

// widgets
import FeaturesResult from "Components/Widgets/FeatureResult";
//import FeatureProduct from "Components/Widgets/FeatureProduct";
//import DayDeal from "Components/Widgets/DayDeal";
//import DetailOffer from "Components/Widgets/DetailOffer";
//import Testimonial from "Components/Widgets/Testimonial";
//import Blog from "Components/Widgets/Blog";
//import ShopCard from "Components/Widgets/ShopCard";
//import Subscribe from "Components/Widgets/Subscribe";
import Notifications from "Components/Widgets/NotificationsBanner";
// import EmailVerified from "Components/Widgets/EmailVerifiedBanner";

// data
import homeBannerData from "Assets/data/homeBannerData";
import featuresData from "Assets/data/featuresData";
import featureProductData from "Assets/data/featureProductData";
import dayDealData from "Assets/data/dayDealData";
import latestArrivalData from "Assets/data/latestArrivalData";
import TestimonialData from "Assets/data/TestimonialData";
import shopCard from "Assets/data/shopCard";

// import api from "Api";
// import { auth } from "../firebase";

export default {
  components: {
    embHomeMainBanner: HomeMainBanner,
    embAsideBannerLottery: AsideBannerLottery,
    // embAsideBannerV2: AsideBannerV2,
    embFeatures: FeaturesResult,
    //embFeatureProduct: FeatureProduct,
    //embDayDeal: DayDeal,
    //embDetailOffer: DetailOffer,
    //embTestimonial: Testimonial,
    //embBlog: Blog,
    //embSubscribe: Subscribe,
    //embShopCard: ShopCard,
    embNotifications: Notifications,
    // embEmailVerified: EmailVerified,
  },
  data() {
    return {
      homeBannerData,
      featuresData,
      featureProductData,
      dayDealData,
      latestArrivalData,
      TestimonialData,
      shopCard,
      blogData: null,
      hideEmailVerificationBanner: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // getBlogData() {
    //   api
    //     .get("blogs.json")
    //     .then((response) => {
    //       this.blogData = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>